<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="secondary" flat>
        <v-toolbar-title>Attributes Codes</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          color="accent"
          small
          to="/inventory/bin-locations/attribute-codes/create"
        >
          <v-icon left dark>mdi-plus</v-icon> New
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-data-table
              :loading="loading"
              loading-text="Loading... Please wait"
              :headers="headers"
              :items="SubLevelCodes"
              :search="search"
            >
              <template v-slot:item.created_at="{ item }">{{
                item.created_at | moment("LLL")
              }}</template>
              <template v-slot:item.action="props">
                <v-btn
                  :to="`/inventory/bin-locations/attribute-code/${props.item.id}`"
                  icon
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    search: "",
    loading: false,
    headers: [
      { text: "Code", value: "AttrValue" },
      { text: "Attribute", value: "bin_field.DispName" },
      { text: "Created On", value: "created_at" },
      { text: "Action", value: "action" },
    ],
    SubLevelCodes: [],
  }),
  methods: {
    getSubLevelCodes() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/bin-locations/atrributes`)
        .then((res) => {
          if (res.ResultCode == 1200) {
            console.log(res.ResponseData);
            self.SubLevelCodes = res.ResponseData;
            self.loading = false;
          }
          if (res.ResultCode == 1043) {
            this.$refs.snackbar.show(res.ResultDesc, "red");
            self.loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.$refs.snackbar.show(err, "red");
          self.loading = false;
        });
    },
  },
  created() {
    this.getSubLevelCodes();
  },
};
</script>